<script setup lang="ts">
import { processedUrl } from '@padlet/vivaldi-client'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    src: string
    width: number
    height: number
    transforms: { [key: string]: string | number }
    alt?: string
  }>(),
  {
    alt: '',
  },
)

const emit = defineEmits<{
  (e: 'error', error: Event): void
}>()

const processedSrc = computed(() => {
  // For relative urls in development
  if (props.src.startsWith('/')) return props.src

  const purl = processedUrl(props.src, props.transforms)
  return purl
})

function onError(error: Event) {
  emit('error', error)
}
</script>

<template>
  <img v-if="src" :src="processedSrc" :width="width" :height="height" :alt="alt" @error="onError" />
</template>
